<route>
{
  "meta": {
    "permission": [
      "customer.view_customer"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-row row wrap>
      <!-- Nombre de cliente -->
      <v-col cols="12" sm="3">
        <ValidationProvider
          vid="first_name"
          :name="$t('first_name')"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="form.first_name"
            autocomplete="off"
            outlined
            :error-messages="errors[0]"
            :label="$t('first_name')"
            class="secondary--text"
          ></v-text-field>
        </ValidationProvider>
      </v-col>

      <!-- Apellido de cliente -->
      <v-col cols="12" sm="3">
        <ValidationProvider
          vid="last_name"
          :name="$t('last_name')"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="form.last_name"
            autocomplete="off"
            outlined
            :error-messages="errors[0]"
            :label="$t('last_name')"
            class="secondary--text"
          ></v-text-field>
        </ValidationProvider>
      </v-col>

      <!-- Nombre de la empresa del cliente -->
      <v-col cols="12" sm="3">
        <ValidationProvider
          vid="customer"
          :name="$t('company')"
          v-slot="{ errors }"
        >
          <v-autocomplete
            outlined
            :error-messages="errors[0]"
            v-model="form.customer"
            item-text="company_name"
            item-value="pk"
            :items="customers"
            :append-icon="customers.length > 10 ? 'fa-search' : ''"
            :label="$t('company')"
            :clearable="customers.length > 10"
          />
        </ValidationProvider>
      </v-col>

      <!-- Tipos de cliente -->
      <v-col cols="12" sm="3">
        <ValidationProvider
          vid="level"
          :name="$tc('level', 2)"
          v-slot="{ errors }"
        >
          <v-select
            autocomplete="off"
            outlined
            :error-messages="errors[0]"
            v-model="form.level"
            item-text="name"
            item-value="pk"
            :items="levels"
            :label="$tc('level', 2)"
          />
        </ValidationProvider>
      </v-col>

      <!-- Botón para limpiar formulario -->
      <v-col cols="12" sm="3">
        <v-btn color="secondary" @click="clear">
          {{ $t('clear') }}
        </v-btn>
      </v-col>
    </v-row>

    <!-- Tabla de clientes -->
    <i-table
      :title="$t('customersReport')"
      :headers="headers"
      app="customer.customer"
      api="customer"
      :opt="tableOptions"
      dontCreate
      dontRemove
      dontSearch
    >
      <template v-slot:extraToolbar>
        <v-divider class="mx-4" inset vertical />
        <i-btn
          text
          :small="$vuetify.breakpoint.smAndDown"
          icon="fa-file-excel"
          :title="$t('download', { value: $tc('customer', 2) })"
          color="text-dark"
          @click="getCustomersExcel"
        />
      </template>
    </i-table>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      customers: [],
      levels: [],
      firstCompany: 0,
      form: {
        first_name: '',
        last_name: '',
        customer: '',
        level: ''
      }
    }
  },
  created() {
    this.setCompany()
  },
  computed: {
    /**
     * headers
     * Propiedad computada que devuelve los encabezados para la tabla.
     * Cada encabezado incluye una etiqueta de texto para la visualización
     * y un valor que corresponde al campo de datos en la tabla.
     *
     * @returns {Array} Array de objetos de encabezados
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    headers() {
      return [
        { text: this.$t('company_name'), value: 'company_name' },
        { text: this.$t('first_name'), value: 'first_name' },
        { text: this.$t('last_name'), value: 'last_name' },
        { text: this.$tc('email', 1), value: 'email' },
        { text: this.$t('phone_number'), value: 'phone' },
        { text: this.$tc('level', 1), value: 'level.name' }
      ]
    },
    /**
     * tableOptions
     * Propiedad computada que construye las opciones para la tabla.
     * Incluye el ID de la compañía actual, el ID del cliente y el ID
     * del tipo de cliente basado en la selección del usuario.
     * Si no se selecciona un cliente o nivel, se asigna null.
     *
     * @returns {Object} Opciones para la tabla
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    tableOptions() {
      return {
        company_id: this.firstCompany,
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        customer_id: this.form.customer || null,
        level_id: this.form.level || null
      }
    },
    ...mapGetters({
      myCompanies: 'session/myCompanies'
    })
  },
  methods: {
    /**
     * getCustomersExcel
     * Descarga asíncronamente los datos de los clientes en formato Excel.
     * Construye la llamada a la API con los parámetros necesarios y activa
     * la acción de descarga.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getCustomersExcel() {
      const byPartialSearch =
        '?first_name=' +
        this.form.first_name +
        '&last_name=' +
        this.form.last_name
      const bySelection = `?company_id=${this.firstCompany}&customer_id=${this.form.customer}&level_id=${this.form.level}`
      const params = byPartialSearch + '&' + bySelection.substring(1)

      await this.downloadFile({
        api: 'customer',
        method: 'excel',
        filename: 'Customers',
        action: 'download',
        ext: 'xlsx',
        opt: {
          pk: 1,
          params: params
        }
      })
    },
    /**
     * setCompany
     * Método para asignar la compañía a la que pertenece el usuario al
     * filtro de la empresa de la tabla
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    setCompany() {
      if (this.myCompanies.length > 0) {
        this.firstCompany = this.myCompanies[0].id
      }
    },
    /**
     * getCustomers
     * Obtiene asíncronamente la lista de clientes activos asociados con la
     * compañía del usuario y actualiza la propiedad de datos de clientes.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getCustomers() {
      const { data } = await this.$api.customer.list({
        opt: {
          params: {
            active: true,
            company_id: this.firstCompany
          }
        }
      })
      this.customers = data.results
    },
    /**
     * getLevels
     * Obtiene asíncronamente la lista de tipos de cliente asociados con la
     * compañía del usuario y actualiza la propiedad de datos de tipos de
     * cliente.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getLevels() {
      const { data } = await this.$api.customer.level.list({
        opt: {
          params: {
            company_id: this.firstCompany
          }
        }
      })
      this.levels = data.results
    },
    /**
     * clear
     * Restablece los campos del formulario para el cliente y el nivel a su
     * estado inicial vacío.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    clear() {
      this.form = {
        first_name: '',
        last_name: '',
        customer: '',
        level: ''
      }
    }
  },
  mounted() {
    this.getCustomers()
    this.getLevels()
  }
}
</script>
